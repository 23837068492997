import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/secondchance.scss"

const SecondChance = () => {
  const hasForm = true
  const images = [""]

  return (
    <Layout>
      <SEO
        lang="en"
        title="Second Chance Program at Oral Surgery Specialists of Oklahoma"
        description="It is time for a brand new, healthy, beautiful smile."
      />

      <div className="sc-program">
        <div className="sc__hero">
          <div className="sc__hero--desktop">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1659042618/Programs/second-chance-desktop-hero-image.jpg"
              alt="program banner"
            />
          </div>
          <div className="sc__hero--mobile">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1659042617/Programs/second-chance-mobile-hero-image.jpg"
              alt="program banner"
            />
          </div>
        </div>

        <div className="sc__section">
          <div className="">
            <div className="sc__about-grid--content">
              <p className="sc__subheading">
                A Second Chance for a Healthy Smile{" "}
              </p>
              <br />
              <p>
                Oral Surgery Specialists of Idaho is thrilled to announce the
                launch of their Second Chance program. The program was created
                to help a deserving member of the Pocatello, Blackfoot, and
                Preston communities receive a complimentary smile makeover.
                Second Chance is open to individuals who suffer from extensive
                oral decay, including missing or failing teeth, and cannot
                afford the costly treatment to restore their smile. Are you
                dreaming of a new smile? The Second Chance recipient will
                receive a full upper and lower arch restoration to completely
                restore and transform their smile.
              </p>

              <p className="sc__subheading mb-1 mt-2">About Second Chance</p>
              <p>
                The oral surgeons at Oral Surgery Specialists of Idaho look
                forward to giving back to the community and completing the
                treatment. A full-arch restoration involves the permanent
                placement of implant-supported dentures, resulting in a new,
                natural-looking smile. Please share information about our new
                Second Chance program with family, friends, and neighbors that
                are in need of a smile transformation.
              </p>

              <p className="sc__subheading mb-3 mt-3 has-text-centered">
                Thank you for your interest in our Second Chance program. The
                2024 application period has ended. Stay tuned to our Facebook
                and Instagram for updates!
              </p>

              <p>
                Stay tuned to our{" "}
                <a
                  title="Follow us on Facebook"
                  href="https://www.facebook.com/oralsurgeryidaho/"
                  target="_blank"
                  rel="noopener">
                  Facebook
                </a>
                 and{" "}
                <a
                  title="Follow us on Instagram"
                  href="https://www.instagram.com/oralsurgery_idaho/"
                  target="_blank"
                  rel="noopener">
                  Instagram
                </a>{" "}
                for future announcements!
              </p>
              <p>
                <i>
                  Learn more about{" "}
                  <Link
                    title="Learn more about dental implants"
                    to="/procedure/dental-implants-pocatello-id/">
                    dental implants
                  </Link>{" "}
                  and{" "}
                  <Link
                    title="Learn more about implant-supported dentures"
                    to="/procedure/implant-supported-dentures-bridges/">
                    implant-supported dentures
                  </Link>{" "}
                  to see how they can benefit your smile. Whether you need to
                  replace one tooth or an entire arch of teeth, our team has a
                  solution for you.
                </i>
              </p>
            </div>

            {/* <div className="sc__about-grid--info">
              <div className="sc__brackets sc__brackets--desktop">
                <div>
                  <p className="sc__subheading">How To Apply</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by September 30, 2024.</li>
                  </ul>

                  {hasForm && (
                    <a
                      className="standard-button contained mx-auto"
                      href="https://secureform.seamlessdocs.com/f/aaad0e2e8d4584a74bc93eace39eefae?embedded=true"
                      target="_blank">
                      Submit Application
                    </a>
                  )}
                </div>
                <div>
                        <p className="sc__subheading">Treatment Partners:</p>
                        <ul>
                            <li>Restorative Partner: Dr. Rosa Mathai from <a href='https://www.westportaldentalcare.com/' title='Go to West Portal Dental Care Website' target="_blank">West Portal Dental Care</a></li>
                            <li>Dental Lab: </li>
                            <li>Implant Provider: </li>
                        </ul>
                    </div> 
              </div>
            </div> */}
          </div>
        </div>

        {/* <div className="sc__section close-sfs-slider close-sc-slider">
            <ImageSlider
              images={images}
              adaptiveHeight={true}
              useArrows={true}
            />
          </div> */}
      </div>
    </Layout>
  )
}

export default SecondChance
